import React, { Suspense, useState, useRef } from "react";
import Dashboard from "./pages/dashboard";
import { ApolloProvider } from "@apollo/client";
import { client } from "./client";
import { BrowserRouter, Link } from "react-router-dom";
import { Route, Routes, useLocation } from "react-router";
import { ConfigProvider, Spin, BackTop } from "antd";
import language from "antd/lib/locale/hu_HU";
import {
  LoginOutlined,
  LogoutOutlined,
  DashboardFilled,
  HomeFilled,
  MobileFilled,
  //SearchOutlined,
  UpOutlined,
  //CarFilled,
  UserOutlined,
  //BookFilled,
  //DollarCircleFilled,
  //CompassFilled,
  //ContactsFilled,
  //EnvironmentOutlined,
  ClockCircleFilled,
  AuditOutlined,
  CameraFilled,
} from "@ant-design/icons";

import moment from "moment";
import "moment/locale/hu";
import { Layout, Menu } from "antd";
import RequireAuth, { AuthProvider, Login, useAuth } from "./auth";
const Courier = React.lazy(() => import("./pages/courier"));
//import Addresses from "./pages/addresses";
const Couriers = React.lazy(() => import("./pages/couriers"));
//const Deliveries = React.lazy(() => import("./pages/deliveries"));
//const Orders = React.lazy(() => import("./pages/orders"));
//const Settlings = React.lazy(() => import("./pages/settling"));
//const Contacts = React.lazy(() => import("./pages/contacts"));
//const DeliveryAreas = React.lazy(() => import("./pages/deliveryAreas"));
//const Summary = React.lazy(() => import("./pages/summary"));
const Haulage = React.lazy(() => import("./pages/haulage"));
const ServiceStates = React.lazy(() => import("./pages/servicestate"));
const { Header, Content, Sider } = Layout;

const Main = () => {
  const location = useLocation();
  const auth = useAuth();
  const [sider, setSider] = useState(+(localStorage.getItem("sider") || 0));

  const content = useRef();

  const menuItems = [
    { path: "/", title: "Főoldal", icon: <HomeFilled /> },
    //{ path: "/orders", title: "Megrendelések", icon: <BookFilled /> },
    //{ path: "/deliveries", title: "Kiszállítások", icon: <CarFilled /> },
    //{ path: "/settling", title: "Elszámolás", icon: <DollarCircleFilled /> },
    //{ path: "/summary", title: "Összesítés" },
    //{
    //  path: "/deliveryAreas",
    //  title: "Kiszállítási területek",
    //  icon: <CompassFilled />,
    //},
    //{ path: "/couriers", title: "Futárok", icon: <UserOutlined /> },
    //{ path: "/contacts", title: "Megrendelők", icon: <ContactsFilled /> },
    //{ path: "/addresses", title: "Címtár", icon: <EnvironmentOutlined /> },
    {
      path: "/servicestate",
      title: "Szolgaltatas",
      icon: <ClockCircleFilled />,
    },
    {
      path: "/haulage",
      title: "Szállítasi költségek",
      icon: <AuditOutlined />,
    },
    { path: "/couriers", title: "Futárok", icon: <UserOutlined /> },
    { path: "/courier", title: "Menetterv", icon: <DashboardFilled /> },
    {
      path: "https://site.ekonyha.hu",
      title: "Fotózás",
      icon: <CameraFilled />,
      external: true,
    },
  ].filter((x) => auth.can(`read${x.path.replace("/", ":")}`));
  return (
    <Layout className="layout">
      <Header
        style={{
          paddingLeft: 10,
          display: "flex",
        }}
      >
        <div className="logo" />

        <h2
          style={{
            display: "inline-block",
            color: "white",
            paddingLeft: 10,
            flexGrow: 1,
          }}
        >
          {moment().format("YYYY-MM-DD")} | {moment().format("W")}. hét
          (v1.16.1)
        </h2>
        <div>
          {(auth.user && (
            <>
              <Link
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                to="/"
                onClick={auth.logout}
              >
                <LogoutOutlined />
                &nbsp; Kijelentkezes
              </Link>
            </>
          )) || (
            <>
              <Link to="/login">
                <LoginOutlined />
                &nbsp; Bejelentkezes
              </Link>
            </>
          )}
        </div>
      </Header>
      <Layout>
        <Sider
          width={200}
          className="site-layout-background"
          collapsible
          collapsed={!sider}
          onCollapse={() => {
            localStorage.setItem("sider", +!sider);
            setSider(!sider);
          }}
        >
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[location.pathname]}
            style={{ height: "100%", borderRight: 0 }}
          >
            {menuItems.map((x) => (
              <Menu.Item key={x.path} icon={x?.icon}>
                {x.external ? (
                  <a href={x.path}>{x.title}</a>
                ) : (
                  <Link to={x.path}>{x.title}</Link>
                )}
              </Menu.Item>
            ))}

            <Menu.Item icon={<MobileFilled />}>
              <a href="https://futar.ekonyha.hu">Webapp</a>
            </Menu.Item>

            {/* <Menu.Item key="/">
            <Link to="/">Főoldal</Link>
          </Menu.Item>
          <Menu.Item key="/orders">
            <Link to="/orders/">Megrendelések</Link>
          </Menu.Item>
          <Menu.Item key="/deliveries">
            <Link to="/deliveries">Kiszállítások</Link>
          </Menu.Item>
          <Menu.Item key="/settling">
            <Link to="/settling">Elszámolás</Link>
          </Menu.Item>
          <Menu.Item key="/summary">
            <Link to="/summary">Összesítés</Link>
          </Menu.Item>
          <Menu.Item key="/deliveryAreas">
            <Link to="/deliveryAreas">Kiszállítási területek</Link>
          </Menu.Item>
          <Menu.Item key="/couriers">
            <Link to="/couriers">Futárok</Link>
          </Menu.Item>
          <Menu.Item key="/contacts">
            <Link to="/contacts">Megrendelők</Link>
          </Menu.Item>
          <Menu.Item key="/haulage">
            <Link to="/haulage">Szállítasi költségek</Link>
          </Menu.Item> */}
          </Menu>
        </Sider>

        <Layout>
          <Content
            style={{
              padding: "0",
              height: "calc(100vh - 64px)",
            }}
          >
            <div
              ref={content}
              id="content"
              style={{
                //padding: "0 50px",
                overflowY: "auto",
                maxHeight: "calc(100%)",
                paddingBottom: 60,
              }}
            >
              <Suspense
                fallback={
                  <div className="center">
                    <Spin />
                  </div>
                }
              >
                <Routes>
                  <Route
                    path="/"
                    element={
                      <RequireAuth>
                        <Dashboard />
                      </RequireAuth>
                    }
                  />
                  <Route path="login" element={<Login />} />
                  <Route
                    path="couriers"
                    element={
                      <RequireAuth>
                        <Couriers />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="servicestate"
                    element={
                      <RequireAuth>
                        <ServiceStates />
                      </RequireAuth>
                    }
                  />
                  {/* <Route
                    path="couriers"
                    element={
                      <RequireAuth>
                        <Couriers />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="deliveries"
                    element={
                      <RequireAuth>
                        <Deliveries />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="orders"
                    element={
                      <RequireAuth>
                        <Orders />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="settling"
                    element={
                      <RequireAuth>
                        <Settlings />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="deliveryAreas"
                    element={
                      <RequireAuth>
                        <DeliveryAreas />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="addresses"
                    element={
                      <RequireAuth>
                        <Addresses />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="contacts"
                    element={
                      <RequireAuth>
                        <Contacts />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="summary"
                    element={
                      <RequireAuth>
                        <Summary />
                      </RequireAuth>
                    }
                  />

                   */}
                  <Route
                    path="haulage"
                    element={
                      <RequireAuth>
                        <Haulage />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="courier"
                    element={
                      <RequireAuth>
                        <Courier />
                      </RequireAuth>
                    }
                  />
                </Routes>
              </Suspense>
            </div>
            <BackTop target={() => content.current}>
              <div
                style={{
                  height: 40,
                  width: 40,
                  lineHeight: "40px",
                  borderRadius: 4,
                  backgroundColor: "#1088e9",
                  color: "#fff",
                  textAlign: "center",
                  fontSize: 14,
                }}
              >
                <UpOutlined />
              </div>
            </BackTop>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

const App = () => (
  <AuthProvider>
    <ConfigProvider locale={language}>
      <BrowserRouter>
        <ApolloProvider client={client}>
          <Main />
        </ApolloProvider>
      </BrowserRouter>
    </ConfigProvider>
  </AuthProvider>
);

export default App;
