import React from "react";
import { useLocation, useNavigate, Navigate } from "react-router";
import { Form, Input, Button } from "antd";
import { client } from "./client";
import $Couriers from "./components/couriers.gql";
//import confirm from "antd/lib/modal/confirm";
const users = [
  {
    id: "0",
    username: "admin",
    password: "ek2022D",
    permissions: ["read:*"],
  },
];

const fakeAuthProvider = {
  signin({ username, password }) {
    return new Promise(async (resolve, reject) => {
      let u = users.find(
        (u) => u.username === username && u.password === password
      );
      if (u) {
        return resolve(u);
      }

      const { data } = await client.query({
        query: $Couriers,
      });
      u = (data?.couriers || []).find(
        (c) =>
          c.pin.toString() === password.toString() &&
          (c.phone.toString() === username.toString() ||
            c.jarat.toString() === username.toString())
      );

      if (u) {
        return resolve({ ...u, permissions: ["read:courier"] });
      }

      reject();
    });
  },
  signout() {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
};

let AuthContext = React.createContext(null);

export function AuthProvider({ children }) {
  const stored = JSON.parse(localStorage.getItem("user") || "null");
  let [user, setUser] = React.useState(stored);

  let signin = (newUser, callback) => {
    return fakeAuthProvider.signin(newUser).then((user) => {
      setUser(user);
      localStorage.setItem("user", JSON.stringify(user));
      callback?.();
    });
  };

  let logout = (callback) => {
    fakeAuthProvider.signout().then(() => {
      setUser(null);
      localStorage.removeItem("user");
      typeof callback == "function" && callback?.();
    });
    /* confirm({
      cancelText: "Mégse",
      okText: "Igen",
      title: "A változások elveszhetnek. Biztos kijelentkezel?",
      content: "Kérlek zárd le elõbb a nyitott napod ha még nem tetted.",
      onOk: () => {
        
      },
    }); */
  };

  let can = (perm) => {
    if (!user) {
      return false;
    }
    if (user.permissions.includes("read:*")) {
      return true;
    }
    return user.permissions.includes(perm);
  };

  let value = { user, signin, logout, can };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export default function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user || !auth.can(`read${location.pathname.replace("/", ":")}`)) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

//export function Logout() {
//  let auth = useAuth();
//  let location = useLocation();
//  auth.logout();
//  return <Navigate to="/login" state={{ from: location }} replace />;
//}

export function Login() {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  let from = location.state?.from?.pathname || "/";

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Form
        name="basic"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        initialValues={{ remember: true }}
        onFinish={({ username, password }) => {
          auth.signin({ username, password }, () => {
            navigate(!isNaN(Number(username)) ? '/courier' : from, { replace: true });
          });
        }}
        autoComplete="off"
      >
        <Form.Item
          label="Felhasználónév"
          name="username"
          rules={[
            { required: true, message: "Kérlek add meg a felhasználónevet!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Jelszó"
          name="password"
          rules={[{ required: true, message: "Kérlek add meg a jelszót!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 10, span: 14 }}>
          <Button type="primary" htmlType="submit">
            Bejelentkezés
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
