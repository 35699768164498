import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Button,
  DatePicker,
  Form,
  Layout,
  Tabs,
  Input,
  Select,
  Modal,
  Popover,
  Segmented,
  message,
} from "antd";
import moment from "moment";
import { client } from "../../client";
import { notify } from "../../utils";
import $Create from "../servicestate/create.gql";
import $Restart from "./restart.gql";
import $Swap from "./swap.gql";
import $CreateSwap from "./createSwap.gql";
import $UpdateSwap from "./updateSwap.gql";
import $SendMail from "./sendmail.gql";
import CourierSelect from "../../components/courierSelect";

import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "antd/lib/form/Form";
const Handlebars = require("handlebars/dist/cjs/handlebars");

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    [{ align: [] }, "direction"],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const helyettesitok = [
  { key: `Ügyfél neve:`, value: `{{NEV}}` },
  { key: `Ügyfél email:`, value: `{{E_MAIL}}` },
  { key: `Ügyfél egyenleg:`, value: `{{EGYENLEG}}` },
  { key: `Járat szám:`, value: `{{JARAT_NO}}` },
  { key: `Brand:`, value: `{{Brand}}` },
  { key: `Dátum:`, value: `{{date}}` },
  { key: `Hét:`, value: `{{het}}` },
  { key: `Logó kép:`, value: `{{{logo}}}` },
  { key: `Telefonszám:`, value: `{{{tel}}}` },
  { key: `Honlap:`, value: `{{{www}}}` },
];

const MailPreview = ({ date, values }) => {
  const [brand, setBrand] = useState("EK");
  const c = Handlebars.default.compile(`
                  <html><body>
                  ${values.content}
                  </body>
                  </html>`);

  const fromC = Handlebars.compile(values.from);
  const from = fromC({
    Brand: {
      EK: "Egészségkonyha",
      CS: "CsirkeRizs",
      BE: "BéresAlexandra",
    }[brand],
  });

  const content = c({
    ...values,

    NEV: "Test Elek",
    E_MAIL: "test@elek.hu",
    JARAT_NO: values.jarat,

    EGYENLEG: 0,
    Brand: "Egészségkonyha",
    from,
    date: date.format("YYYY-MM-DD"),
    het: date.isoWeek(),
    logo: `<img src="https://egeszsegkonyha.hu/templates/yootheme/cache/uj_logo_ek2-b2b5eae1.png" alt="logo">`,
    tel: `<a href="tel:+36703331360">+36703331360</a> <a href="tel:+3612332240">+3612332240</a>`,
    www: `<a href="https://egeszsegkonyha.hu/">https://egeszsegkonyha.hu/</a>`,
  });

  return (
    <>
      Brand:
      <Select
        value={brand}
        onChange={(v) => {
          setBrand(v);
        }}
      >
        <Select.Option value={"EK"}>Egészségkonyha</Select.Option>
        <Select.Option value={"CS"}>CsirkeRizs</Select.Option>
        <Select.Option value={"BE"}>BéresAlexandra</Select.Option>
      </Select>
      <h2>Feladó: {from}</h2>
      <h2>Tárgy: {values.subject}</h2>
      <div
        style={{
          border: "solid 1px black",
          padding: 20,
        }}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></div>
    </>
  );
};

export default function Dashboard() {
  const rq = useRef();

  return (
    <Layout style={{ padding: "24px" }}>
      <Layout.Content style={{ background: "white", padding: "24px" }}>
        <Tabs>
          <Tabs.TabPane tab="Szolgaltatasok" key="services">
            <h1>Szolgáltatások</h1>
            <Button
              onClick={() => {
                const now = moment();
                notify(
                  client.mutate({
                    mutation: $Create,
                    refetchQueries: ["serviceStates"],
                    variables: {
                      data: {
                        from: now.toISOString(true),
                        to: now.add(30, "minute").toISOString(true),
                        type: {
                          set: ["SETTLE", "SORT"],
                        },
                      },
                    },
                  })
                );
              }}
            >
              Gyors zárás
            </Button>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Járat késés értesítő" key="email">
            <Form
              initialValues={{
                debug: "",
                from: `"{{Brand}}" <noreply@egeszsegkonyha.hu>`,
                cc: [
                  `mezes.kalman+jarat_keses@gmail.com`,
                  `janos.horovitz+jarat_keses@gmail.com`,
                ],
              }}
              onFinish={({ date, ...values }) => {
                Modal.confirm({
                  title: `Elõnézet`,
                  content: <MailPreview date={moment(date)} values={values} />,
                  okText: "Küldés",
                  width: "100%",

                  onOk() {
                    notify(
                      client.mutate({
                        mutation: $SendMail,
                        variables: {
                          cc: [],
                          ...values,
                          date: date.format("YYYY-MM-DD"),
                        },
                      })
                    );
                  },
                });
              }}
            >
              <Form.Item name="date" label="Dátum">
                <DatePicker />
              </Form.Item>
              <Form.Item name="jarat" label="Járat">
                <CourierSelect useJaratNo />
              </Form.Item>
              <Form.Item name="debug" label="Email Cím (feluliras)">
                <Input type="email" placeholder="Minden ügyfél" />
              </Form.Item>
              <Form.Item name="from" label="Feladó">
                <Input type="text" />
              </Form.Item>
              <Form.Item name="cc" label="Másolatot kap">
                <Select
                  mode="tags"
                  style={{ minWidth: "200px" }}
                  placeholder="email"
                ></Select>
              </Form.Item>
              <Form.Item name="subject" label="Tárgy">
                <Input type="subject" />
              </Form.Item>

              <Form.Item
                name="content"
                label={
                  <strong>
                    HTML Tartalom (handlebars)
                    <br />
                    <Popover
                      title="helyettesítõk"
                      placement="topLeft"
                      content={
                        <ul
                          style={{
                            padding: 0,
                            listStyle: "none",
                          }}
                        >
                          {helyettesitok.map((x) => (
                            <li>
                              {x.key}&nbsp;
                              <strong
                                style={{
                                  color: "blue",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  var caretPosition = rq.current
                                    .getEditor()
                                    .getSelection(true);
                                  rq.current
                                    .getEditor()
                                    .insertText(caretPosition, x.value);
                                }}
                                href="#"
                              >
                                {x.value}
                              </strong>
                            </li>
                          ))}
                        </ul>
                      }
                    >
                      <Button type="link" href="#">
                        Használható helyettesítõk
                      </Button>
                    </Popover>
                  </strong>
                }
              >
                <ReactQuill
                  ref={rq}
                  modules={modules}
                  formats={formats}
                  preserveWhitespace
                />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit">Elõnézet</Button>
              </Form.Item>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane id="swap" tab="Szallitasi nap">
            <Swap />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Szerver" key="server">
            <Button
              onClick={() => {
                notify(
                  client.mutate({
                    mutation: $Restart,
                  })
                );
              }}
            >
              Újraindítás
            </Button>
          </Tabs.TabPane>
        </Tabs>
      </Layout.Content>
    </Layout>
  );
}

function Swap() {
  const [date, setDate] = useState(moment());
  const md = moment(date);
  const het = md.isoWeek();
  const nap = md.isoWeekday();
  const ev = md.year();
  const [createSwap] = useMutation($CreateSwap);
  const [updateSwap] = useMutation($UpdateSwap);
  const { data, loading } = useQuery($Swap, {
    variables: {
      at: `${ev}-${het}-${nap}`,
    },
  });

  const [form] = useForm();
  useEffect(() => {
    const swap = data?.swaps?.find((x) => x.at === `${ev}-${het}-${nap}`);

    form.setFieldsValue({
      id: swap?.id,
      value: swap?.value || null,
    });
  }, [data, ev, het, nap, form]);

  return (
    <div>
      <DatePicker value={date} onChange={setDate} />
      {!loading && (
        <Form
          form={form}
          key={data?.swaps?.length}
          onFinish={(values) => {
            console.log(values);
            if (!values.id) {
              return createSwap({
                variables: {
                  at: `${ev}-${het}-${nap}`,
                  value: values.value,
                },
                refetchQueries: [$Swap],
              }).then(r => {
                message.info("Sikeres mentés");
              }).catch((err) => {
                message.error("Hiba a mentés soran");
              });
            }
            return updateSwap({
              variables: {
                id: values.id,
                value: values.value,
              },
              refetchQueries: [$Swap],
            }).then(r => {
              message.info("Sikeres mentés");
            }).catch((err) => {
              message.error("Hiba a mentés soran");
            });
          }}
        >
          <h2>{`${ev} ${het}.hét ${nap} nap`}</h2>
          
          <Form.Item name="id" label="" hidden>
            <Input />
          </Form.Item>
          <Form.Item label="Kiszallitasi mod" name="value">
            <Segmented
              options={[
                {
                  label: "Alapértelmezett",
                  value: null,
                },
                {
                  label: "Kovetkezo nap szallitando",
                  value: true,
                },
                {
                  label: "Kovetkezo nap NEM szallitando",
                  value: false,
                },
              ]}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Mentés
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
