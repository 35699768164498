import { useQuery } from "@apollo/client";
import { Select } from "antd";
import $Couriers from "./couriers.gql";

export default function CourierSelect(props) {
  const { data, loading } = useQuery($Couriers);
  const { enableNull = true, defaultFirst = false, defaultValue } = props;
  return (
    <Select
      {...props}
      style={{
        ...(props.style || {}),
        minWidth: 180,
      }}
      loading={loading}
      placeholder="Szállító választása"
      showSearch
      defaultValue={defaultFirst ? data?.couriers?.[0] : defaultValue || undefined}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {!props.children && enableNull && (
        <Select.Option key="-" value={null}>
          -
        </Select.Option>
      )}
      {props.children}
      {data?.couriers?.map?.((x) => (
        <Select.Option key={x.id} value={props.useJaratNo ? x.jarat : x.id}>
           {`(${x.jarat}) ${x.name}`}
        </Select.Option>
      ))}
    </Select>
  );
}
