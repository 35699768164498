import React from "react";
import { withServiceWorkerUpdater } from "@3m1/service-worker-updater";

import { Modal } from "antd";

const ServiceWorkerWrapper = ({
  children,
  newServiceWorkerDetected,
  onLoadNewServiceWorkerAccept,
}) => {
  return (
    <>
      {newServiceWorkerDetected ? (
        <Modal
          visible={true}
          title="Új verzió érhetõ el!"
          showIcon
          onOk={onLoadNewServiceWorkerAccept}
          okText="Frissíts"
        />
      ) : children}
      
    </>
  );
};

export default withServiceWorkerUpdater(ServiceWorkerWrapper);
